import {Json} from '../../components/JsonObjectCard';
import {RequestExample} from '../../components/RequestExampleCard';
import {RequestDefinitionData} from '../../components/RequestDefinition';
import {Attribute} from '../../components/ObjectAttribute';

const request: RequestExample = {
    title: {
        verb: 'GET',
        endpointUrl: '/api/data-query',
    },
    curl:
        `curl --location \\
--request GET '<YOUR SERVER>/api/data-query' \\
--header 'Authorization: Bearer <TOKEN>'`
};

const queryParameters: Attribute[] = [
    {
        name: 'query',
        type: 'string',
        description: 'SQL to be run.',
    }
]

const response: Json = {
    title: 'RESPONSE',
    json: [{
        id: 30,
        num: 'S10040'
    }],
};

const data: RequestDefinitionData = {
    callName: 'data-query-param',
    title: 'Execute data query',
    description: 'Execute a data query against the database using SQL.',
    attributes: [],
    requestObject: request,
    responseObject: response,
    queryParameters: queryParameters,
}

export default data;
