import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Divider} from '@material-ui/core';
import ObjectHeader from '../ObjectHeader';
import RequestDefinition from '../RequestDefinition';

import {importExportHeader} from '../../data/importExport/importExport';
import importRequest from '../../data/importExport/importRequest';
import importRequestJson from '../../data/importExport/importRequestJson';
import dataQueryBody from '../../data/importExport/dataQueryBody';
import dataQueryParam from '../../data/importExport/dataQueryParam';

export default new RouteComponent(`${apiRoutePrefix}/import`, () => {

    return (
        <div>
            <ObjectHeader data={ importExportHeader }/>

            <Box mb={ 3 }>
                <Divider/>
            </Box>

            <RequestDefinition {...importRequest}/>
            <RequestDefinition {...importRequestJson}/>
            <RequestDefinition {...dataQueryBody}/>
            <RequestDefinition {...dataQueryParam}/>
        </div>
    );
});
